<template>
  <section>
    <section class="flex items-center w-full py-12 pt-20 transition duration-700 bg-primary-dark">
      <div
        class="container mx-auto relative h-[4000px] flex items-center transition-all duration-700"
        :class="{ 'max-h-[24rem]': showPage, 'max-h-screen': !showPage }"
      >
        <div
          :class="{ 'opacity-0 pointer-events-none p-10': showPage, 'opacity-100': !showPage }"
          class="
            absolute
            top-0
            right-0
            flex
            justify-end
            p-6
            transition
            duration-700
            transform
            md:top-1/2 md:-translate-y-96
            lg:p-10
          "
        >
          <LogoFrSVG class="h-24 md:h-32" v-if="$i18n.locale === 'fr'" />
          <LogoEnSVG class="h-24 md:h-32" v-else-if="$i18n.locale === 'en'" />
          <LogoNlSVG class="h-24 md:h-32" v-else />
        </div>
        <div
          class="max-w-xl text-white transition-all duration-700 transform md:mt-0"
          :class="{ '-mt-10': showPage, '-mt-24': !showPage }"
        >
          <h1 class="relative p-6 text-left lg:p-10">
            <span class="block pb-16 text-6xl font-light lg:pb-20">{{ $t("home.yes-to") }}</span>
            <div
              class="
                absolute
                h-[53px]
                overflow-hidden
                left-6
                lg:left-10
                top-20
                lg:top-28
                mt-4
                lg:mt-2
              "
            >
              <div
                class="transition-all duration-1000 ease-in-out"
                :style="{ transform: `scale(1) )` }"
              >
                <button
                  class="
                    duration-700
                    transition
                    ease-in-out
                    w-full
                    display
                    block
                    text-left
                    whitespace-nowrap
                    text-6xl
                    font-bold
                    relative
                    origin-left
                    -top-2.5
                    pr-6
                  "
                  :style="{
                    transform: `translateY(-${index * 53}px) scale(${index === i ? '1' : '0.8'})`,
                    'line-height': '53px',
                  }"
                  v-for="(word, i) in words"
                  :key="word"
                >
                  {{ $t(word) }}
                </button>
              </div>
            </div>
            <span class="block text-4xl text-left text-primary">{{ $t("home.subtitle") }} </span>
          </h1>
        </div>
        <button
          :class="{ 'opacity-0 pointer-events-none': showPage, 'opacity-100': !showPage }"
          class="
            fixed
            block
            mx-auto
            text-center text-white
            transition
            duration-700
            transform
            -translate-x-1/2
            bottom-6
            left-1/2
          "
          @click="handleScroll"
        >
          <span class="block w-full mb-2 text-xl whitespace-nowrap">{{ $t("splash.text") }}</span>
          <span><ArrowSVG class="w-6 h-6 mx-auto transform rotate-90 fill-current" /></span>
        </button>
      </div>
    </section>
    <section
      class="transition duration-700"
      :class="{ 'opacity-100 translate-y-0': showPage, 'opacity-0 translate-y-10': !showPage }"
    >
      <section class="pb-10 bg-primary-dark">
        <div class="container flex flex-wrap mx-auto bg-white rounded-3xl">
          <h1 class="px-6 pt-10 text-4xl xl:px-10">{{ $t("home.you-are") + "..." }}</h1>
          <div class="flex flex-wrap w-full p-10 px-0 md:px-6 xl:px-10">
            <button
              class="w-full mb-8 lg:mb-0 lg:w-1/3 lg:pr-2"
              @click="$router.push({ name: 'BusinessConsumer', hash: `#consumer` })"
            >
              <div>
                <div
                  class="h-72 lg:h-48"
                  :style="{
                    background: 'url(/img/nanac-img-consument.png)',
                    backgroundSize: 'cover',
                  }"
                >
                  <img
                    class="absolute opacity-0 pointer-events-none"
                    src="/img/nanac-img-consument.png"
                    alt=""
                  />
                </div>
                <span
                  class="
                    relative
                    flex
                    items-center
                    justify-between
                    p-3
                    text-xl text-white
                    uppercase
                    border-b-2
                    special
                    border-b-black border-opacity-20
                    bg-secondary-oliva
                    oliva
                  "
                  >{{ $t("home.consumer") }} <ArrowSVG class="relative z-50 w-4 h-4 fill-current"
                /></span>
              </div>
            </button>
            <button
              class="w-full mb-8 lg:mb-0 lg:w-1/3 lg:px-2"
              @click="$router.push({ name: 'BusinessConsumer', hash: `#business` })"
            >
              <div class="bg-white">
                <div
                  class="h-72 lg:h-48"
                  :style="{
                    background: 'url(/img/nanac-img-bedrijf.png)',
                    backgroundSize: 'cover',
                  }"
                >
                  <img
                    class="absolute opacity-0 pointer-events-none"
                    src="/img/nanac-img-bedrijf.png"
                    alt=""
                  />
                </div>
                <span
                  class="
                    relative
                    flex
                    items-center
                    justify-between
                    p-3
                    text-xl text-white
                    uppercase
                    border-b-2
                    special
                    border-b-black border-opacity-20
                    bg-secondary-rose
                  "
                  >{{ $t("home.business") }} <ArrowSVG class="relative z-50 w-4 h-4 fill-current"
                /></span>
              </div>
            </button>
            <button
              class="w-full mb-8 lg:mb-0 lg:w-1/3 lg:pl-2"
              @click="$router.push({ name: 'BusinessConsumer', hash: `#service_provider` })"
            >
              <div class="bg-white">
                <div
                  class="h-72 lg:h-48"
                  :style="{
                    background: 'url(/img/nanac-img-dienstverlener.png)',
                    backgroundSize: 'cover',
                  }"
                >
                  <img
                    class="absolute opacity-0 pointer-events-none"
                    src="/img/nanac-img-dienstverlener.png"
                    alt=""
                  />
                </div>
                <span
                  class="
                    relative
                    flex
                    items-center
                    justify-between
                    p-3
                    text-xl text-white
                    uppercase
                    border-b-2
                    special
                    gold
                    bg-secondary-gold
                    border-b-black border-opacity-20
                  "
                  >{{ $t("home.service-provider") }}
                  <ArrowSVG class="relative z-50 w-4 h-4 fill-current"
                /></span>
              </div>
            </button>
          </div>
          <div class="w-full p-10 text-white rounded-t-3xl bg-primary-dark">
            <h1 class="py-10 text-3xl text-primary">
              {{ $t("home.follow") }} <strong>{{ $t("home.debate") }}</strong>
            </h1>

            <a
              class="twitter-timeline"
              data-height="750"
              href="https://twitter.com/nanac_be?ref_src=twsrc%5Etfw"
              >Tweets by nanac_be</a
            >
          </div>
        </div>
      </section>
      <!-- Main -->
      <main>
        <section class="py-10 lg:p-4 lg:px-10">
          <div class="container p-10 mx-auto bg-white rounded-xl">
            <section class="flex flex-wrap">
              <div class="flex flex-col w-full pr-3 text-left lg:w-1/2">
                <div class="">
                  <h1>
                    <span class="block mb-4 text-4xl"> {{ $t("home.content.title1") }}</span>
                  </h1>
                  <!-- <h2 class="mb-4 text-xl font-bold text-secondary-rose text-third"> -->
                  <h2 class="mb-4 text-xl font-bold text-secondary-rose text-third">
                    {{ $t("home.content.subtitle1") }}
                  </h2>
                  <p class="mb-4 text-lg">
                    {{ $t("home.content.p1") }}
                  </p>
                </div>
                <div class="mt-auto">
                  <button
                    @click="$router.push({ name: 'About' })"
                    class="
                      relative
                      flex
                      items-center
                      justify-between
                      w-full
                      max-w-sm
                      p-4
                      text-2xl text-white
                      uppercase
                      border-b-2 border-black
                      special
                      bg-secondary-rose
                      border-opacity-30
                    "
                  >
                    <span class="pr-10">{{ $t("home.b-1") }}</span>
                    <ArrowSVG class="relative z-50 w-4 h-4 fill-current" />
                  </button>
                </div>
              </div>
              <div
                class="
                  w-full
                  pt-4
                  mt-6
                  text-left
                  border-t-2
                  lg:w-1/2 lg:pl-3 lg:mt-0 lg:pt-0 lg:border-0
                  border-primary-dark
                "
              >
                <div class="">
                  <h1>
                    <span class="block mb-4 text-4xl"> {{ $t("home.content.title2") }}</span>
                  </h1>
                  <h2 class="mb-4 text-xl font-bold text-secondary-rose text-third">
                    {{ $t("home.content.subtitle2") }}
                  </h2>
                  <p class="mb-4 text-lg">
                    {{ $t("home.content.p2") }}
                  </p>
                </div>
                <button
                  @click="$router.push({ name: 'About' })"
                  class="
                    relative
                    flex
                    items-center
                    justify-between
                    w-full
                    max-w-sm
                    p-4
                    text-2xl text-white
                    uppercase
                    border-b-2 border-black
                    special
                    bg-secondary-rose
                    border-opacity-30
                  "
                >
                  <span class="pr-10">{{ $t("home.b-2") }}</span>
                  <ArrowSVG class="relative z-50 w-4 h-4 fill-current" />
                </button>
              </div>
            </section>
          </div>
        </section>
      </main>
      <Footer />
    </section>
  </section>
</template>

<script>
import Footer from "../components/Footer.vue";
export default {
  name: "Home",
  components: {
    Footer,
  },
  data() {
    return {
      index: 0,
      showPage: false,
      words: ["quality", "authenticity", "trust"],
    };
  },
  methods: {
    handleScroll() {
      this.$emit("header", true);
      this.showPage = true;
      window.removeEventListener("scroll", this.handleScroll);
    },
  },
  created() {
    this.$emit("header", false);
    setTimeout(() => {
      window.addEventListener("scroll", this.handleScroll);
    }, 500);

    setInterval(() => {
      this.index++;
      if (this.index >= this.words.length) this.index = 0;
    }, 2000);
  },
  destroyed() {
    this.$emit("header", true);
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
